import { useEffect, useState, useContext } from "react";
import { GetAll } from "../../../service/officer";
import { Container, HeaderContainer } from "./styled";
import ModernTable from "../../../components/Tables/ModernTable";
import { AuthContext } from "../../../context/contextGlobal/authContext";
import { Button, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

export default function OrdersService() {
  const theme = useTheme();
  const [officers, setOfficers] = useState([]);
  const { setModal } = useContext(AuthContext);

  const columns = [
    { field: 'funcional', headerName: 'Funcional', sortable: true },
    { field: 'status', headerName: 'Status', sortable: true },
    { 
      field: 'userName', 
      headerName: 'Nome do Usuário', 
      sortable: true,
      renderCell: (row) => row.user?.username || '-'
    },
    { 
      field: 'userEmail', 
      headerName: 'Email', 
      sortable: true,
      renderCell: (row) => row.user?.email || '-'
    },
    { 
      field: 'userPhone', 
      headerName: 'Telefone', 
      sortable: true,
      renderCell: (row) => row.user?.phone || '-'
    },
    {
      field: 'actions',
      headerName: 'Ações',
      sortable: false,
      renderCell: (row) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => handleAssign(row)}
        >
          Designar
        </Button>
      )
    }
  ];

  const handleAssign = (officer) => {
    setModal({
      type: 'assignOrders',
      data: officer
    });
  };

  const init = async () => {
    const result = await GetAll();

    if (result.error) {
      console.log(result.message);
    } else {
      const formattedData = result.data.map(officer => ({
        id: officer.id,
        funcional: officer.attributes.funcional,
        status: officer.attributes.status,
        user: officer.attributes.user?.data?.attributes
      }));
      setOfficers(formattedData);
    }
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <Typography variant="h5" sx={{ color: theme.palette.primary.light, fontWeight: 600 }}>
          Gerenciar Ordens de Serviço
        </Typography>
        <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
          Gerencie as designações de ocorrências e propriedades que precisam ser visitadas para os policiais ativos no sistema
        </Typography>
      </HeaderContainer>

      <ModernTable 
        columns={columns}
        data={officers}
        pagination={true}
        rowsPerPageOptions={[5, 10, 25]}
        loading={false}
        scroll={true}
      />
    </Container>
  )
}