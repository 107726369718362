import styled from 'styled-components';
import { 
    Table, 
    TableContainer as MuiTableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    Pagination
} from '@mui/material';

export const TableWrapper = styled(MuiTableContainer)`
    background: ${props => props.theme.palette.background.paper};
    border-radius: 16px;
    box-shadow: 0 2px 12px ${props => props.theme.palette.shadow};
    transition: all 0.3s ease;
    border: 1px solid ${props => props.theme.palette.primary.pale};
    overflow: ${props => props.enableScroll ? 'auto' : 'hidden'};
    margin-bottom: 24px;
    min-height: 200px;
    width: 100%;
    max-width: 100%;

    &::-webkit-scrollbar {
        height: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${props => `${props.theme.palette.primary.main}40`};
        border-radius: 8px;
        
        &:hover {
            background-color: ${props => `${props.theme.palette.primary.main}60`};
        }
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
    scrollbar-width: thin;
    scrollbar-color: ${props => `${props.theme.palette.primary.main}40 transparent`};
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    gap: 16px;
    color: ${props => props.theme.palette.text.secondary};
    
    span {
        margin-top: 8px;
        font-size: 14px;
    }
`;

export const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 32px;
    text-align: center;
    color: ${props => props.theme.palette.text.secondary};

    svg {
        color: ${props => props.theme.palette.primary.main};
        margin-bottom: 16px;
        opacity: 0.8;
    }

    h3 {
        margin: 0;
        margin-bottom: 8px;
        color: ${props => props.theme.palette.text.primary};
        font-weight: 600;
    }

    p {
        margin: 0;
        font-size: 14px;
        max-width: 300px;
        line-height: 1.5;
    }
`;

export const StyledTable = styled(Table)`
    min-width: 650px;
    width: 100%;
`;

export const TableHeader = styled(TableHead)`
    background: ${props => `${props.theme.palette.primary.main}08`};
`;

export const HeaderRow = styled(TableRow)`
    &:hover {
        background: none;
    }
`;

export const HeaderCell = styled(TableCell)`
    color: ${props => props.theme.palette.text.primary};
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 16px 24px;
    border-bottom: 2px solid ${props => props.theme.palette.primary.pale};
`;

export const BodyRow = styled(TableRow)`
    transition: all 0.2s ease;
    cursor: ${props => props.onClick ? 'pointer' : 'default'};

    &:hover {
        background: ${props => `${props.theme.palette.primary.main}05`};
    }

    &:nth-of-type(odd) {
        background: ${props => `${props.theme.palette.primary.main}02`};
        
        &:hover {
            background: ${props => `${props.theme.palette.primary.main}05`};
        }
    }
`;

export const BodyCell = styled(TableCell)`
    padding: 16px 24px;
    color: ${props => props.theme.palette.text.secondary};
    font-size: 14px;
    border-bottom: 1px solid ${props => props.theme.palette.primary.pale};
`;

export const StyledSortLabel = styled(TableSortLabel)`
    &.MuiTableSortLabel-root {
        color: ${props => props.theme.palette.text.primary};
        
        &:hover {
            color: ${props => props.theme.palette.primary.main};
        }
        
        &.Mui-active {
            color: ${props => props.theme.palette.primary.main};
            
            .MuiTableSortLabel-icon {
                color: ${props => props.theme.palette.primary.main};
            }
        }
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 16px;
    background: ${props => props.theme.palette.background.paper};
    border-top: 1px solid ${props => props.theme.palette.primary.pale};
`;

export const StyledPagination = styled(Pagination)`
    .MuiPaginationItem-root {
        color: ${props => props.theme.palette.text.primary};
        
        &.Mui-selected {
            background: ${props => `${props.theme.palette.primary.main}20`};
            color: ${props => props.theme.palette.primary.main};
            
            &:hover {
                background: ${props => `${props.theme.palette.primary.main}30`};
            }
        }

        &:hover {
            background: ${props => `${props.theme.palette.primary.main}10`};
        }
    }
`; 