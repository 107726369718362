import { ReadObject, SaveObject } from './storage'

const isDev = false

export const API_ENDPOINT = isDev ? 'http://localhost:1337/api' : 'https://api.rrapp.com.br/api'

if (!API_ENDPOINT) {
	console.error('API_ENDPOINT is not configured properly');
}
console.log('API_ENDPOINT:', API_ENDPOINT)

export const GetHeaders = async (authenticated) => {
	const headers = { 'Content-Type': 'application/json' }
	const authentication = await ReadObject('authentication')
	if (authenticated && authentication?.jwt) {
		headers.Authorization = `Bearer ${authentication?.jwt}`
	}
	return { headers }
}

export const ServerFetch = async (url, options, authenticated) => {
	const { headers } = await GetHeaders(authenticated)
	try {
		const response = await fetch(url, { ...options, headers })
		
		if (!response.ok) {
			const errorBody = await response.text();
			console.log('Resposta de erro completa:', errorBody);
			try {
				const errorJson = JSON.parse(errorBody);
				return { 
					error: true, 
					message: errorJson.error?.message || `HTTP error! status: ${response.status}`,
					details: errorJson 
				};
			} catch {
				return { 
					error: true, 
					message: `HTTP error! status: ${response.status}`,
					rawError: errorBody 
				};
			}
		}
		
		if (response?.statusCode === 403 && authenticated) {
			await SaveObject('authentication', {})
		}

		const responseData = await response.json();
		return responseData;

	} catch(error) {
		console.log('Erro na requisição:', error);
		return { error: true, message: error.message }
	}
}

export const GET = async (path, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'GET'
		},
		authenticated
	)
}

export const POST = async (path, body, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'POST',
			body: JSON.stringify(body)
		},
		authenticated
	)
}

export const PUT = async (path, body, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'PUT',
			body: JSON.stringify(body)
		},
		authenticated
	)
}

export const DELETE = async (path, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'DELETE'
		},
		authenticated
	)
}




export const ReadAddressesByZipCode = async (zipCode) => {
    try{
        let result = await fetch(`https://viacep.com.br/ws/${ zipCode }/json/`);
        return result.json();
    }catch(err){ return false; }
}
  

export const ResolveUri = (result) => {
    const path = result?.path ? result?.path 
                : result?.fileCopyUri ? result?.fileCopyUri 
                : result?.uri ? result?.uri 
                : result?.croppedImage ? result?.croppedImage 
                : typeof result === 'string' ? result : null     
    return path?.indexOf('file://') === -1 ? `file://${ path }` : path
}

export const PrepareFile = result => {
    if (!result) {
        throw new Error('Arquivo não fornecido');
    }

    const uri = ResolveUri(result);
    if (!uri) {
        throw new Error('URI da imagem não encontrada');
    }

    const name = uri.split('/').pop();
    const match = /\.(\w+)$/.exec(name);
    const type = match ? `image/${match[1]}` : `image/jpeg`; // default to jpeg if can't determine

    return { 
        uri, 
        name, 
        type 
    };
}

export const ServerUploadImage = async (result) => {  
  return await PostImage(PrepareFile(result));
}

export const PostImage = async (fileToUpload) => {
    const formData = new FormData();
    
    // Garante que o arquivo tem todas as propriedades necessárias
    if (!fileToUpload.uri || !fileToUpload.type) {
        return { error: true, message: 'Arquivo inválido' };
    }

    formData.append('files', {
        uri: fileToUpload.uri,
        type: fileToUpload.type,
        name: fileToUpload.name || `image-${Date.now()}.jpg`
    });

    let { headers } = await GetHeaders(true);
    delete headers['Content-Type']; // Importante para upload multipart

    try {
        const response = await fetch(`${API_ENDPOINT}/upload`, {
            method: 'POST',
            body: formData,
            headers
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Upload error response:', errorText);
            return { 
                error: true, 
                message: `Erro no upload: ${response.status}`,
                details: errorText
            };
        }

        const responseJson = await response.json();
        if (!responseJson?.[0]) {
            return { 
                error: true, 
                message: 'Resposta inválida do servidor' 
            };
        }

        return responseJson[0];
    } catch (error) {
        console.error('Upload error:', error);
        return { 
            error: true, 
            message: error.message || 'Erro ao fazer upload da imagem'
        };
    }
}