import { POST, GET, PUT } from "./api";

export const Create = async (data) => {
  const body = {
    data: {
      officer: data.officerId,
      occurrences: data.occurrences || [],
      properties: data.properties || [],
      status: 'active'
    }
  };

  return await POST('/officer-assignments', body, true);
};

export const GetByOfficer = async (officerId) => {
  return await GET(`/officer-assignments?filters[officer][id][$eq]=${officerId}&populate=*`);
};

export const UpdateStatus = async (assignmentId, status) => {
  return await PUT(`/officer-assignments/${assignmentId}`, {
    data: {
      status
    }
  });
}; 