import { GET, POST } from "./api";

export const Create = async (officerData) => {
    console.log("🚀 ~ Dados recebidos:", officerData);
    
    const payload = {
        name: officerData.fullName,
        funcional: officerData.funcional,
        patrol: officerData.patrolId,
    };
    console.log("🚀 ~ Payload a ser enviado:", payload);

    const response = await POST('/auth/local/register-officer', payload, true);

    if (response.error) {
        throw new Error(response.message || 'Erro ao cadastrar policial');
    }

    return response;
} 

export const GetAll = async () => {
    const response = await GET('/officers', true);
    return response;
}