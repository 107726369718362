import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { 
    MapWrapper, 
    StyledMapContainer, 
    MapHeader, 
    MapTitle,
    MapControlsContainer,
    ControlButton,
    FullscreenMapContainer
} from './styled';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { 
    MagnifyingGlassPlus, 
    MagnifyingGlassMinus, 
    CornersOut,
    MapTrifold
} from '@phosphor-icons/react';

// Corrigir o problema dos ícones do Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Renomeando o componente para MapControlsComponent
function MapControlsComponent({ map, handleZoomIn, handleZoomOut, toggleFullscreen, toggleMapType, isSatellite }) {
    return (
        <MapControlsContainer>
            {/* <ControlButton onClick={handleZoomIn} title="Aumentar zoom">
                <MagnifyingGlassPlus size={20} weight="duotone" />
            </ControlButton>
            <ControlButton onClick={handleZoomOut} title="Diminuir zoom">
                <MagnifyingGlassMinus size={20} weight="duotone" />
            </ControlButton> */}
            <ControlButton 
                onClick={toggleMapType} 
                title="Alternar modo satélite"
                active={isSatellite}
            >
                <MapTrifold size={20} weight="duotone" />
            </ControlButton>
            <ControlButton onClick={toggleFullscreen} title="Tela cheia">
                <CornersOut size={20} weight="duotone" />
            </ControlButton>
        </MapControlsContainer>
    );
}

// Componente para atualizar a visualização do mapa
function MapUpdater({ selectedProduct }) {
    const map = useMap();

    useEffect(() => {
        if (selectedProduct) {
            const lat = parseFloat(selectedProduct.lat);
            const lng = parseFloat(selectedProduct.long);
            
            if (!isNaN(lat) && !isNaN(lng)) {
                map.setView([lat, lng], 15);
            }
        }
    }, [selectedProduct, map]);

    return null;
}

function ZoomController({ onZoomIn, onZoomOut }) {
    const map = useMap();

    const handleZoomIn = () => {
        if (map && map._loaded && map._container) {
            setTimeout(() => {
                try {
                    map.zoomIn();
                } catch (error) {
                    console.warn('Erro ao dar zoom in:', error);
                }
            }, 100);
        }
    };

    const handleZoomOut = () => {
        if (map && map._loaded && map._container) {
            setTimeout(() => {
                try {
                    map.zoomOut();
                } catch (error) {
                    console.warn('Erro ao dar zoom out:', error);
                }
            }, 100);
        }
    };

    useEffect(() => {
        if (map && map._loaded) {
            if (onZoomIn) onZoomIn(handleZoomIn);
            if (onZoomOut) onZoomOut(handleZoomOut);
        }
    }, [map, onZoomIn, onZoomOut]);

    return null;
}

const ModernMap = ({ selectedProduct, setSelectedProduct, properties }) => {
    const theme = useTheme();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [map, setMap] = useState(null);
    const [isSatellite, setIsSatellite] = useState(false);

    // Função para obter as coordenadas iniciais
    const getInitialCoordinates = () => {
        if (selectedProduct) {
            const lat = parseFloat(selectedProduct.lat);
            const lng = parseFloat(selectedProduct.long);
            if (!isNaN(lat) && !isNaN(lng)) {
                return [lat, lng];
            }
        }

        if (properties && properties.length > 0) {
            for (const property of properties) {
                const lat = parseFloat(property.lat);
                const lng = parseFloat(property.long);
                if (!isNaN(lat) && !isNaN(lng)) {
                    return [lat, lng];
                }
            }
        }

        return [-15.793889, -47.882778]; // Coordenadas padrão de Brasília
    };

    const handleZoomIn = () => {
        if (map) map.zoomIn();
    };

    const handleZoomOut = () => {
        if (map) map.zoomOut();
    };

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    const toggleMapType = () => {
        setIsSatellite(!isSatellite);
    };

    const handleMarkerClick = (property) => {
        setSelectedProduct(property);
    };

    const getMarkerIcon = (property) => {
        const isSelected = selectedProduct?.id === property.id;
        const size = isSelected ? 12 : 8;
        const color = (() => {
            switch (property.status?.toLowerCase()) {
                case 'ativo':
                case 'aprovada':
                case 'aprovado':
                    return '#66bb6a';
                case 'reprovada':
                case 'reprovado':
                    return '#f44336';
                case 'aguardando':
                    return '#ffa726';
                case 'inativo':
                case 'inativa':
                    return '#9e9e9e';
                default:
                    return theme.palette.primary.main;
            }
        })();

        return L.divIcon({
            html: `<div style="
                background-color: ${color};
                width: ${size * 2}px;
                height: ${size * 2}px;
                border-radius: 50%;
                border: 2px solid white;
                box-shadow: 0 0 4px rgba(0,0,0,0.4);
            "></div>`,
            className: '',
            iconSize: [size * 2, size * 2],
        });
    };

    useEffect(() => {
        if (isFullscreen && map) {
            setTimeout(() => {
                try {
                    map.invalidateSize();
                } catch (error) {
                    console.warn('Erro ao redimensionar mapa:', error);
                }
            }, 100);
        }
    }, [isFullscreen, map]);

    const MapContent = () => (
        <>
            <StyledMapContainer isFullscreen={isFullscreen}>
                <MapControlsComponent 
                    map={map}
                    handleZoomIn={handleZoomIn}
                    handleZoomOut={handleZoomOut}
                    toggleFullscreen={toggleFullscreen}
                    toggleMapType={toggleMapType}
                    isSatellite={isSatellite}
                />
                
                <MapContainer
                    center={getInitialCoordinates()}
                    zoom={15}
                    style={{ width: '100%', height: '100%' }}
                    whenCreated={setMap}
                    zoomControl={false}
                    attributionControl={true}
                >
                    <TileLayer
                        url={isSatellite 
                            ? 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                            : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        }
                        attribution={isSatellite 
                            ? 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                            : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        }
                    />
                    <MapUpdater selectedProduct={selectedProduct} />
                    <ZoomController onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
                    
                    {properties?.map((property, index) => {
                        const lat = parseFloat(property.lat);
                        const lng = parseFloat(property.long);
                        
                        if (!isNaN(lat) && !isNaN(lng)) {
                            return (
                                <Marker
                                    key={index}
                                    position={[lat, lng]}
                                    icon={getMarkerIcon(property)}
                                    eventHandlers={{
                                        click: () => handleMarkerClick(property)
                                    }}
                                >
                                    <Popup>
                                        <strong>{property.name}</strong>
                                        <br />
                                        Status: {property.status}
                                    </Popup>
                                </Marker>
                            );
                        }
                        return null;
                    })}
                </MapContainer>
            </StyledMapContainer>
        </>
    );

    if (isFullscreen) {
        return (
            <FullscreenMapContainer>
                <MapContent />
            </FullscreenMapContainer>
        );
    }

    return (
        <MapWrapper>
            <MapContent />
        </MapWrapper>
    );
};

export default ModernMap; 