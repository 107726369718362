import React from 'react';
import { 
  CardContainer, 
  Title, 
  StatsGrid, 
  StatItem, 
  StatValue, 
  StatLabel 
} from './styled';
import { 
  CheckCircle, 
  Eye, 
  Clock, 
  X, 
  Power, 
  Calculator 
} from '@phosphor-icons/react';

const PatrolStatisticCard = ({ patrol, stats }) => {
  const statItems = [
    {
      label: 'Aprovadas',
      value: stats.aprovadas,
      icon: <CheckCircle size={24} weight="duotone" color="#B4E760" />,
      color: '#B4E760' // Verde
    },
    {
      label: 'Visitadas',
      value: stats.visitadas,
      icon: <Eye size={24} weight="duotone" color="#60A5E7" />,
      color: '#60A5E7' // Azul
    },
    {
      label: 'Aguardando',
      value: stats.aguardando,
      icon: <Clock size={24} weight="duotone" color="#E7C160" />,
      color: '#E7C160' // Amarelo
    },
    {
      label: 'Reprovadas',
      value: stats.reprovadas,
      icon: <X size={24} weight="duotone" color="#E76060" />,
      color: '#E76060' // Vermelho
    },
    {
      label: 'Inativas',
      value: stats.inativas,
      icon: <Power size={24} weight="duotone" color="#9B9B9B" />,
      color: '#9B9B9B' // Cinza
    },
    {
      label: 'Total',
      value: stats.total,
      icon: <Calculator size={24} weight="duotone" color="#60E7B4" />,
      color: '#60E7B4' // Verde água
    }
  ];

  return (
    <CardContainer elevation={3}>
      <Title>{patrol.name}</Title>
      <StatsGrid>
        {statItems.map((item, index) => (
          <StatItem key={index}>
            {item.icon}
            <StatValue color={item.color}>{item.value}</StatValue>
            <StatLabel>{item.label}</StatLabel>
          </StatItem>
        ))}
      </StatsGrid>
    </CardContainer>
  );
};

export default PatrolStatisticCard; 