import styled from 'styled-components';
import { Paper } from '@mui/material';

export const CardContainer = styled(Paper)`
  padding: 24px;
  min-width: 300px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette?.background?.paper || '#1e1e1e'};
  transition: all 0.3s ease-in-out;
  margin: 8px;
  height: 100%;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
`;

export const Title = styled.h3`
  color: #B4E760;
  font-size: 1.5rem;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(180, 231, 96, 0.2);
`;

export const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: ${({ color }) => color ? `${color}10` : 'rgba(180, 231, 96, 0.05)'};
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ color }) => color ? `${color}20` : 'rgba(180, 231, 96, 0.1)'};
  }
`;

export const StatValue = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${({ color }) => color || '#B4E760'};
  margin-bottom: 4px;
`;

export const StatLabel = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.palette?.text?.secondary || '#999'};
  text-align: center;
  line-height: 1.2;
`;

export const CarouselContainer = styled.div`
  position: relative;
  margin-bottom: 32px;
  padding: 0 24px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  .slick-slider {
    width: 100%;
    margin: 0 auto;
  }

  .slick-track {
    display: flex;
    margin: 0 -10px;
  }

  .slick-slide {
    height: auto;
    padding: 0 10px;
    box-sizing: border-box;
    
    > div {
      height: 100%;
    }
  }

  .slick-list {
    margin: 0;
    overflow: hidden;
  }

  .slick-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const SlickArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.direction === 'left' ? 'left: -20px;' : 'right: -20px;'}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette?.background?.paper || '#1e1e1e'};
  border: 1px solid #B4E760;
  color: #B4E760;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: all 0.2s ease;
  padding: 0;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &:hover:not(:disabled) {
    background: #B4E760;
    color: #1e1e1e;
    box-shadow: 0 4px 12px rgba(180, 231, 96, 0.2);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    border-color: #666;
    color: #666;
  }

  &:active:not(:disabled) {
    transform: translateY(-50%) scale(0.95);
  }

  svg {
    width: 24px;
    height: 24px;
  }
`; 