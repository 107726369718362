import styled from 'styled-components';

export const DrawerOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${props => props.theme.palette.colors.shadow};
    z-index: 999999999;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: all 0.3s ease-in-out;
`;

export const DrawerContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    background-color: ${props => props.theme.palette.background.paper};
    box-shadow: 2px 0 8px ${props => props.theme.palette.colors.shadow};
    z-index: 1000;
    transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const DrawerHeader = styled.div`
    padding: 24px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    background: ${props => props.theme.palette.background.default};
    
    h3 {
        color: ${props => props.theme.palette.text.primary};
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
    }
`;

export const DrawerContent = styled.div`
    padding: 24px;
    background: ${props => props.theme.palette.background.default};
    height: calc(100% - 85px);
    overflow-y: auto;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    color: ${props => props.theme.palette.text.primary};
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    svg {
        width: 24px;
        height: 24px;
        color: ${props => props.theme.palette.text.primary};
        transition: all 0.3s ease;
    }

    &:hover {
        background: ${props => props.theme.palette.primary.main};
        
        svg {
            color: ${props => props.theme.palette.text.primary};
        }
    }
`; 