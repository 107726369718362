import React from 'react';
import { Chart } from "react-google-charts";
import { CircularProgress } from '@mui/material';
import { ChartLine } from '@phosphor-icons/react';
import { 
    ChartContainer, 
    LoadingContainer, 
    EmptyStateContainer,
    ChartTitle,
    ChartWrapper
} from './styled';

const BarChart = ({ data, title, loading }) => {
    const options = {
        chartArea: { 
            width: '65%', 
            height: '80%', 
            top: 10,
            right: 10,
            bottom: 48,
            left: 100
        },
        hAxis: {
            title: 'Total',
            minValue: 0,
            textStyle: { 
                color: '#FFFFFF',
                fontSize: 12,
                bold: true
            },
            titleTextStyle: { 
                color: '#FFFFFF',
                fontSize: 14,
                bold: true,
                italic: false
            },
            gridlines: { color: '#51594C' },
            baselineColor: '#51594C'
        },
        vAxis: {
            title: 'Município',
            textStyle: { 
                color: '#FFFFFF',
                fontSize: 12,
                bold: true
            },
            titleTextStyle: { 
                color: '#FFFFFF',
                fontSize: 14,
                bold: true,
                italic: false
            },
            gridlines: { color: '#51594C' },
            baselineColor: '#51594C'
        },
        backgroundColor: 'transparent',
        legend: { position: 'none' },
        colors: ['#D5D96C'],
        tooltip: { 
            isHtml: true,
            textStyle: { 
                color: '#FFFFFF',
                fontSize: 14,
                bold: true
            },
            showColorCode: true,
            backgroundColor: '#2C312A',
            borderColor: '#51594C',
            borderWidth: 1
        },
    };

    if (loading) {
        return (
            <LoadingContainer>
                <CircularProgress size={40} thickness={4} />
                <span>Carregando dados...</span>
            </LoadingContainer>
        );
    }

    if (!data || data.length <= 1) {
        return (
            <EmptyStateContainer>
                <ChartLine size={48} weight="duotone" />
                <h3>Sem dados disponíveis</h3>
                <p>Não há dados suficientes para gerar o gráfico.</p>
            </EmptyStateContainer>
        );
    }

    return (
        <ChartContainer>
            <h3>{title}</h3>
            <ChartWrapper>
                <Chart
                    chartType="BarChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={options}
                />
            </ChartWrapper>
        </ChartContainer>
    );
};

export default BarChart; 