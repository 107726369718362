import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  max-height: 70vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.palette.background.default};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.palette.primary.main};
    border-radius: 4px;
  }
`;

export const OfficerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.colors.border};
  border-radius: 8px;
`;

export const Section = styled.div`
  background: ${props => props.theme.palette.background.paper};
  border: 1px solid ${props => props.theme.palette.colors.border};
  border-radius: 8px;
  padding: 16px;

  h6 {
    color: ${props => props.theme.palette.primary.light};
    margin-bottom: 16px;
    font-weight: 600;
  }

  .MuiFormGroup-root {
    gap: 8px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    
    .MuiCheckbox-root {
      color: ${props => props.theme.palette.primary.main};
      
      &.Mui-checked {
        color: ${props => props.theme.palette.primary.light};
      }
      
      &.Mui-disabled {
        color: ${props => props.theme.palette.text.disabled};
      }
    }
    
    .MuiFormControlLabel-label {
      color: ${props => props.theme.palette.text.primary};
      
      &.Mui-disabled {
        color: ${props => props.theme.palette.text.disabled};
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 8px;
  
  button {
    min-width: 120px;
  }
`; 