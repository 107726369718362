import styled from 'styled-components';

export const FilterSection = styled.div`
    margin-bottom: 24px;
`;

export const FilterTitle = styled.h4`
    color: ${props => props.theme.palette.text.primary};
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
`;

export const FilterGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

export const FilterButtons = styled.div`
    display: flex;
    gap: 12px;
    margin-top: 32px;
    /* position: sticky; */
    bottom: 0;
    background: ${props => props.theme.palette.background.default};
    padding: 16px 0;
    border-top: 1px solid ${props => props.theme.palette.divider};

    button {
        flex: 1;
    }
`;

export const FilterButton = styled.button`
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid ${props => props.selected ? props.theme.palette.primary.main : props.theme.palette.primary.pale};
    background: ${props => props.selected ? props.theme.palette.primary.main : props.theme.palette.background.default};
    color: ${props => props.selected ? props.theme.palette.text.primary : props.theme.palette.text.primary};
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: ${props => props.selected ? props.theme.palette.primary.dark : props.theme.palette.primary.main};
        border-color: ${props => props.selected ? props.theme.palette.primary.dark : props.theme.palette.primary.main};
        color: ${props => props.theme.palette.text.primary};
    }
`;

export const ClearButton = styled.button`
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.palette.error.main};
    background: ${props => props.theme.palette.background.default};
    color: ${props => props.theme.palette.text.primary};
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: ${props => props.theme.palette.error.main};
        color: ${props => props.theme.palette.text.primary};
    }
`;

export const DateInput = styled.input`
    padding: 8px 12px;
    border: 1px solid ${props => props.theme.palette.primary.pale};
    border-radius: 4px;
    font-size: 14px;
    color: ${props => props.theme.palette.text.primary};
    background: ${props => props.theme.palette.background.input};
    outline: none;
    transition: all 0.2s ease;
    width: 100%;

    &:hover {
        border-color: ${props => props.theme.palette.primary.main};
    }

    &:focus {
        border-color: ${props => props.theme.palette.primary.main};
        box-shadow: 0 0 0 2px ${props => `${props.theme.palette.primary.main}20`};
    }
`; 