import React, { useContext, useState, useEffect } from 'react';
import { FormText } from '../../../ui/styled';
import { Container, ButtonContainer, Section, OfficerInfo } from './styled';
import Wrapper from '../Wrapper';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { Button, Checkbox, FormControlLabel, FormGroup, Typography, CircularProgress, Divider } from '@mui/material';
import { GetAvailable as GetAvailableOccurrences } from '../../../service/occurrences';
import { GetAvailable as GetAvailableProperties } from '../../../service/property';
import { Create as CreateAssignment } from '../../../service/officer-assignments';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';

const OCCURRENCE_TYPES = {
  personal: 'Pessoal',
  other: 'Outros',
  suspicious: 'Suspeito',
  obstruction: 'Obstrução'
};

export default function ModalAssignOrders() {
  const theme = useTheme();
  const { modal, setModal } = useContext(AuthContext);
  const [occurrences, setOccurrences] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedOccurrences, setSelectedOccurrences] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const close = () => {
    setModal(null);
  };

  const handleSave = async () => {
    if (!showConfirm) {
      setShowConfirm(true);
      return;
    }

    try {
      setLoading(true);
      const result = await CreateAssignment({
        officerId: modal.data.id,
        occurrences: selectedOccurrences,
        properties: selectedProperties
      });

      if (result.error) {
        toast.error('Erro ao salvar designações');
        return;
      }

      toast.success('Designações salvas com sucesso');
      close();
    } catch (error) {
      console.error('Erro ao salvar designações:', error);
      toast.error('Erro ao salvar designações');
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const [occurrencesResult, propertiesResult] = await Promise.all([
        GetAvailableOccurrences(),
        GetAvailableProperties()
      ]);

      if (occurrencesResult.data) {
        setOccurrences(occurrencesResult.data);
      }

      if (propertiesResult.data) {
        setProperties(propertiesResult.data);
      }
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
      toast.error('Erro ao carregar dados');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleOccurrenceChange = (occurrenceId) => {
    setSelectedOccurrences(prev => {
      if (prev.includes(occurrenceId)) {
        return prev.filter(id => id !== occurrenceId);
      }
      return [...prev, occurrenceId];
    });
    setShowConfirm(false);
  };

  const handlePropertyChange = (propertyId) => {
    setSelectedProperties(prev => {
      if (prev.includes(propertyId)) {
        return prev.filter(id => id !== propertyId);
      }
      return [...prev, propertyId];
    });
    setShowConfirm(false);
  };

  const formatOccurrenceLabel = (occurrence) => {
    const date = new Date(occurrence.attributes.occurrence_date).toLocaleDateString('pt-BR');
    const time = occurrence.attributes.occurrence_time?.slice(0, 5);
    const type = OCCURRENCE_TYPES[occurrence.attributes.occurrence_type] || 'Sem tipo';
    
    return `${date} ${time || ''} - ${type} ${occurrence.attributes.police_report_number ? `- B.O: ${occurrence.attributes.police_report_number}` : ''}`;
  };

  const formatPropertyLabel = (property) => {
    const { name, address } = property.attributes;
    return `${name} - ${address || 'Sem endereço'}`;
  };

  return (
    <Wrapper title="Designar Ocorrências e Propriedades">
      <Container>
        <OfficerInfo>
          <Typography variant="subtitle1" sx={{ color: theme.palette.primary.light }}>
            Policial Selecionado:
          </Typography>
          <Typography sx={{ color: theme.palette.text.primary }}>
            {modal.data.user?.username || 'Sem nome'} - Funcional: {modal.data.funcional}
          </Typography>
        </OfficerInfo>

        <Divider sx={{ my: 2, borderColor: theme.palette.colors.border }} />
        
        {showConfirm ? (
          <Section>
            <Typography variant="h6" sx={{ color: theme.palette.primary.light }}>
              Confirmar Designação
            </Typography>
            <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
              Você está prestes a designar:
            </Typography>
            {selectedOccurrences.length > 0 && (
              <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
                • {selectedOccurrences.length} ocorrência(s)
              </Typography>
            )}
            {selectedProperties.length > 0 && (
              <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
                • {selectedProperties.length} propriedade(s)
              </Typography>
            )}
            <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
              Para o policial {modal.data.user?.username || 'Sem nome'} (Funcional: {modal.data.funcional}).
            </Typography>
            <Typography sx={{ color: theme.palette.text.primary, mt: 1 }}>
              Deseja continuar?
            </Typography>
          </Section>
        ) : (
          <>
            <FormText style={{ color: '#fff' }}>
              Selecione as ocorrências e propriedades disponíveis para designar ao policial
            </FormText>
            
            <Section>
              <Typography variant="h6" sx={{ color: theme.palette.primary.light }}>Ocorrências Disponíveis</Typography>
              <FormGroup>
                {loading ? (
                  <CircularProgress size={24} sx={{ color: theme.palette.primary.light }} />
                ) : occurrences.length === 0 ? (
                  <Typography sx={{ color: theme.palette.primary.light }}>
                    Nenhuma ocorrência disponível para designação
                  </Typography>
                ) : (
                  occurrences.map((occurrence) => (
                    <FormControlLabel
                      key={occurrence.id}
                      control={
                        <Checkbox
                          checked={selectedOccurrences.includes(occurrence.id)}
                          onChange={() => handleOccurrenceChange(occurrence.id)}
                          disabled={loading}
                        />
                      }
                      label={formatOccurrenceLabel(occurrence)}
                    />
                  ))
                )}
              </FormGroup>
            </Section>

            <Section>
              <Typography variant="h6" sx={{ color: theme.palette.primary.light }}>Propriedades Disponíveis</Typography>
              <FormGroup>
                {loading ? (
                  <CircularProgress size={24} sx={{ color: theme.palette.primary.light }} />
                ) : properties.length === 0 ? (
                  <Typography sx={{ color: theme.palette.primary.light }}>
                    Nenhuma propriedade disponível para designação
                  </Typography>
                ) : (
                  properties.map((property) => (
                    <FormControlLabel
                      key={property.id}
                      control={
                        <Checkbox
                          checked={selectedProperties.includes(property.id)}
                          onChange={() => handlePropertyChange(property.id)}
                          disabled={loading}
                        />
                      }
                      label={formatPropertyLabel(property)}
                    />
                  ))
                )}
              </FormGroup>
            </Section>
          </>
        )}

        <ButtonContainer>
          <Button
            variant="contained"
            sx={{
              bgcolor: theme.palette.button.primary,
              '&:hover': {
                bgcolor: theme.palette.button.hover,
              },
              '&:disabled': {
                bgcolor: theme.palette.button.disabled,
              }
            }}
            onClick={handleSave}
            disabled={loading || (selectedOccurrences.length === 0 && selectedProperties.length === 0)}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {loading ? 'Salvando...' : showConfirm ? 'Confirmar' : 'Designar'}
          </Button>
          <Button
            variant="outlined"
            onClick={showConfirm ? () => setShowConfirm(false) : close}
            disabled={loading}
            sx={{
              color: loading ? theme.palette.text.disabled : theme.palette.primary.light,
              borderColor: loading ? theme.palette.text.disabled : theme.palette.primary.light,
              '&:hover': {
                borderColor: theme.palette.primary.light,
                bgcolor: 'transparent'
              }
            }}
          >
            {showConfirm ? 'Voltar' : 'Cancelar'}
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
} 