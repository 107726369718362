import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.palette.dark};
  
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;
