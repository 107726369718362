import React from 'react';
import { 
    CardContainer, 
    CardTitle, 
    CardValue,
    CardContent,
    IconContainer
} from './styled';
import { 
    ChartLineUp, 
    CheckCircle, 
    XCircle, 
    Eye, 
    Clock, 
    ProhibitInset 
} from '@phosphor-icons/react';

const getIconAndColor = (title) => {
    switch (title) {
        case 'Total':
            return {
                icon: <ChartLineUp size={28} weight="duotone" />,
                color: '#60E7B4'
            };
        case 'Reprovadas':
            return {
                icon: <XCircle size={28} weight="duotone" />,
                color: '#E76060'
            };
        case 'Visitadas':
            return {
                icon: <Eye size={28} weight="duotone" />,
                color: '#60A5E7'
            };
        case 'Aguardando':
            return {
                icon: <Clock size={28} weight="duotone" />,
                color: '#E7C160'
            };
        case 'Aprovadas':
            return {
                icon: <CheckCircle size={28} weight="duotone" />,
                color: '#B4E760'
            };
        case 'Inativas':
            return {
                icon: <ProhibitInset size={28} weight="duotone" />,
                color: '#9B9B9B'
            };
        default:
            return {
                icon: <ChartLineUp size={28} weight="duotone" />,
                color: '#60E7B4'
            };
    }
};

const formatValue = (value) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") || '0';
};

const StatisticCard = ({ title, value }) => {
    const { icon, color } = getIconAndColor(title.replace('Total ', ''));
    const formattedValue = formatValue(value);

    return (
        <CardContainer style={{ '--card-color': color }}>
            <CardContent>
                <div>
                    <CardTitle>{title.replace('Total ', '')}</CardTitle>
                    <CardValue>{formattedValue}</CardValue>
                </div>
                <IconContainer>
                    {icon}
                </IconContainer>
            </CardContent>
        </CardContainer>
    );
};

export default StatisticCard; 