import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { ChartBar } from '@phosphor-icons/react';
import { Container, StatsGrid, StatCard } from './styled';

const OccurrenceTypeStats = ({ properties }) => {
  // Calcula totais por tipo de ocorrência
  const calculateTotalsByType = () => {
    const totals = {};
    
    properties?.forEach(property => {
      property.occurrences?.forEach(occurrence => {
        const type = occurrence.occurrence_type || 'Não categorizado';
        totals[type] = (totals[type] || 0) + 1;
      });
    });

    return Object.entries(totals).map(([type, count]) => ({
      type: formatOccurrenceType(type),
      count
    })).sort((a, b) => b.count - a.count); // Ordena por quantidade decrescente
  };

  // Formata o tipo de ocorrência para exibição
  const formatOccurrenceType = (type) => {
    const typeMap = {
      'personal': 'Pessoal',
      'property': 'Propriedade',
      'vehicle': 'Veículo',
      'other': 'Outro'
    };

    return typeMap[type] || type;
  };

  const stats = calculateTotalsByType();

  if (!stats || stats.length === 0) {
    return null;
  }

  return (
    <Container>
      <StatsGrid>
        {stats.map(({ type, count }) => (
          <StatCard key={type} component={Paper} elevation={3}>
            <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold', mb: 1 }}>
              {count}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {type}
            </Typography>
          </StatCard>
        ))}
      </StatsGrid>
    </Container>
  );
};

export default OccurrenceTypeStats; 