import styled from 'styled-components';
import { Box } from '@mui/material';

export const Container = styled.div`
  margin: 20px 0;
  width: 100%;
`;

export const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-top: 24px;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
`;

export const StatCard = styled(Box)`
  padding: 32px;
  border-radius: 12px;
  text-align: center;
  background-color: ${({ theme }) => theme.palette?.background?.paper || '#1e1e1e'};
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  width: 100%;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
`; 