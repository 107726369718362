import styled from 'styled-components';

export const CardContainer = styled.div`
    background: ${props => props.theme.palette.background.paper};
    border-radius: 16px;
    padding: 24px;
    min-width: 200px;
    flex: 1;
    box-shadow: 0 4px 20px ${props => `${props.theme.palette.shadow}40`};
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
    background: linear-gradient(145deg,
        ${props => `${props.theme.palette.background.paper}`} 0%,
        ${props => `${props.theme.palette.background.default}`} 100%
    );

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(90deg, 
            var(--card-color) 0%, 
            ${props => props.theme.palette.primary.light} 100%
        );
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 30px ${props => `${props.theme.palette.shadow}60`};
        border-color: ${props => `var(--card-color)30`};

        &::before {
            opacity: 1;
        }
    }

    @media (max-width: 1200px) {
        min-width: 180px;
        padding: 20px;
    }

    @media (max-width: 768px) {
        min-width: 150px;
        padding: 16px;
    }
`;

export const CardContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 768px) {
        gap: 12px;
    }
`;

export const CardTitle = styled.h3`
    color: var(--card-color);
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;
    padding-bottom: 8px;
    opacity: 0.9;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 30px;
        height: 2px;
        background: var(--card-color);
        opacity: 0.5;
    }

    @media (max-width: 768px) {
        font-size: 12px;
        margin-bottom: 8px;
        padding-bottom: 6px;
    }
`;

export const CardValue = styled.div`
    color: var(--card-color);
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 1.2;
    text-shadow: 0 2px 4px ${props => `${props.theme.palette.shadow}20`};
    margin-bottom: 4px;

    @media (max-width: 1200px) {
        font-size: 32px;
    }

    @media (max-width: 768px) {
        font-size: 28px;
    }
`;

export const IconContainer = styled.div`
    color: var(--card-color);
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: linear-gradient(135deg, 
        ${props => `var(--card-color)15`} 0%, 
        ${props => `var(--card-color)25`} 100%
    );
    border-radius: 14px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid ${props => `var(--card-color)20`};

    ${CardContainer}:hover & {
        background: linear-gradient(135deg, 
            ${props => `var(--card-color)25`} 0%, 
            ${props => `var(--card-color)35`} 100%
        );
        transform: scale(1.05) rotate(5deg);
    }

    @media (max-width: 768px) {
        padding: 12px;
    }
`;