import styled from 'styled-components';

export const HeaderContainer = styled.header`
    background: ${props => props.theme.palette.primary.light};
    border-bottom: 1px solid ${props => props.theme.palette.primary.dark};
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 12px ${props => props.theme.palette.shadow};
    position: relative;
`;

export const LogoContainer = styled.div`
    display: flex; 
    align-items: center;
    gap: 12px;

    img {
        height: 40px;
        object-fit: contain;
    }

    @media (max-width: 768px) {
        width: 100%;
        justify-content: space-between;

        /* Inverte a ordem dos elementos em mobile */
        flex-direction: row-reverse;
    }
`;

export const DesktopOnlyContainer = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const MobileMenuButton = styled.button`
    display: none;
    background: none;
    border: none;
    padding: 8px;
    color: ${props => props.theme.palette.primary.dark};
    cursor: pointer;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0; /* Remove margem à esquerda */
    }
`;

export const MobileMenu = styled.div`
    display: none;
    
    @media (max-width: 768px) {
        display: ${props => props.isOpen ? 'flex' : 'none'};
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: ${props => props.theme.palette.primary.light};
        box-shadow: 0 4px 12px ${props => props.theme.palette.shadow};
        z-index: 1000;
        padding: 8px 0;
    }
`;

export const MobileMenuItem = styled.button`
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 12px 24px;
    background: none;
    border: none;
    color: ${props => props.theme.palette.primary.dark};
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        background: ${props => props.theme.palette.primary.dark};
        color: ${props => props.theme.palette.primary.light};
    }

    svg {
        color: ${props => props.theme.palette.primary.dark};
    }
`;

export const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

export const NavTabs = styled.div`
    display: flex;
    gap: 8px;
`;

export const NavTab = styled.button`
    background: none;
    border: none;
    padding: 8px 16px;
    color: ${props => props.active ? props.theme.palette.primary.dark : props.theme.palette.primary.dark};
    font-weight: ${props => props.active ? '600' : '500'};
    font-size: 14px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    white-space: nowrap;

    &:after {
        content: '';
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 100%;
        height: 2px;
        background: ${props => props.active ? props.theme.palette.primary.dark : 'transparent'};
        transition: all 0.3s ease;
    }

    &:hover {
        color: ${props => props.theme.palette.primary.dark};
    }
`;

export const UserContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const UserInfo = styled.div`
    text-align: right;
`;

export const UserName = styled.div`
    color: ${props => props.theme.palette.text.primary};
    font-weight: 600;
    font-size: 14px;
`;

export const UserRole = styled.div`
    color: ${props => props.theme.palette.text.secondary};
    font-size: 12px;
`;

export const LogoutButton = styled.button`
    background: none;
    border: 1px solid ${props => props.theme.palette.primary.dark};
    border-radius: 8px;
    padding: 8px 12px;
    color: ${props => props.theme.palette.primary.dark};
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;

    svg {
        color: ${props => props.theme.palette.primary.dark};
        transition: all 0.3s ease;
    }

    &:hover {
        background: ${props => props.theme.palette.primary.dark};
        border-color: ${props => props.theme.palette.primary.dark};
        color: ${props => props.theme.palette.primary.light};
        transform: translateY(-1px);

        svg {
            color: ${props => props.theme.palette.primary.light};
        }
    }
`;

export const FilterButton = styled.button`
    display: flex;
    align-items: center;
    gap: 8px;
    background: none;
    border: 1px solid ${props => props.theme.palette.primary.dark};
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    color: ${props => props.theme.palette.primary.dark};
    margin-left: 16px;
    transition: all 0.2s ease;
    white-space: nowrap;

    svg {
        color: ${props => props.theme.palette.primary.dark};
        transition: all 0.2s ease;
    }

    &:hover {
        background: ${props => props.theme.palette.primary.dark};
        border-color: ${props => props.theme.palette.primary.dark};
        color: ${props => props.theme.palette.primary.light};
        transform: translateY(-1px);

        svg {
            color: ${props => props.theme.palette.primary.light};
        }
    }
`; 