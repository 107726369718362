import { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    HeaderContainer,
    LogoContainer,
    MenuContainer,
    NavTabs,
    NavTab,
    UserContainer,
    LogoutButton,
    FilterButton,
    MobileMenuButton,
    MobileMenu,
    MobileMenuItem,
    DesktopOnlyContainer
} from './styled';
import {
    SignOut,
    FunnelSimple,
    List,
    House,
    UserPlus,
    Users,
    FileText,
    MapTrifold
} from '@phosphor-icons/react';
import { AuthContext } from '../../context/contextGlobal/authContext';
import { useProperties } from '../../context/PropertiesContext';
import logo from '../../assets/img/logo.png';
import Drawer from '../Drawer';
import ConfirmDialog from '../ConfirmDialog';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const { properties } = useProperties();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const tabs = [
        { path: '/home', label: 'Home' },
        { path: '/register-officer', label: 'Cadastrar Policial' },
        { path: '/orders-service', label: 'Gerenciar ordens de serviço' },
    ];

    const menuItems = [
        {
            label: 'Home',
            path: '/home',
            icon: <House />
        },
        {
            label: 'Usuários Ativos',
            path: '/activeUsersList',
            icon: <Users />
        },
        {
            label: 'Cadastrar Policial',
            path: '/register-officer',
            icon: <UserPlus />
        },
        {
            label: 'Ordens de Serviço',
            path: '/orders-service',
            icon: <FileText />
        },
        {
            label: 'Dashboard',
            path: '/officer-dashboard',
            icon: <MapTrifold />
        }
    ]

    const handleLogoutClick = () => {
        setIsLogoutDialogOpen(true);
        setIsMobileMenuOpen(false);
    };

    const handleLogoutConfirm = () => {
        localStorage.removeItem('@token');
        setIsLogoutDialogOpen(false);
            navigate('/');
    };

    const handleLogoutCancel = () => {
        setIsLogoutDialogOpen(false);
    };

    const handleFilterClick = () => {
        setIsDrawerOpen(true);
        setIsMobileMenuOpen(false);
    };

    const handleNavigate = (path) => {
        navigate(path);
        setIsMobileMenuOpen(false);
    };

    return (
        <HeaderContainer>
            <LogoContainer>
                <MobileMenuButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    <List size={24} weight="duotone" />
                </MobileMenuButton>
                
                <img src={logo} alt="Logo" />
            </LogoContainer>

            <MobileMenu isOpen={isMobileMenuOpen}>
                {menuItems.map(item => (
                    <MobileMenuItem key={item.path} onClick={() => handleNavigate(item.path)}>
                        {item.icon}
                        {item.label}
                    </MobileMenuItem>
                ))}
                <MobileMenuItem onClick={handleFilterClick}>
                    <FunnelSimple size={20} weight="duotone" />
                    Filtros
                </MobileMenuItem>
                <MobileMenuItem onClick={handleLogoutClick}>
                    <SignOut size={20} weight="duotone" />
                    Sair
                </MobileMenuItem>
            </MobileMenu>

            <DesktopOnlyContainer>
                <MenuContainer>
                    <NavTabs>
                        {tabs.map(tab => (
                            <NavTab
                                key={tab.path}
                                active={location.pathname === tab.path}
                                onClick={() => navigate(tab.path)}
                            >
                                {tab.label}
                            </NavTab>
                        ))}
                    </NavTabs>

                    <UserContainer>
                        <FilterButton onClick={handleFilterClick}>
                            <FunnelSimple size={20} weight="duotone" />
                            Filtros
                        </FilterButton>
                        <LogoutButton onClick={handleLogoutClick}>
                            <SignOut size={20} weight="duotone" />
                            Sair
                        </LogoutButton>
                    </UserContainer>
                </MenuContainer>
            </DesktopOnlyContainer>

            <Drawer 
                isOpen={isDrawerOpen} 
                onClose={() => setIsDrawerOpen(false)}
                data={properties}
            />

            <ConfirmDialog
                open={isLogoutDialogOpen}
                onClose={handleLogoutCancel}
                onConfirm={handleLogoutConfirm}
                title="Confirmar Saída"
                content="Tem certeza que deseja sair do sistema?"
            />
        </HeaderContainer>
    );
};

export default Header;