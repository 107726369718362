import { GET, POST, PUT } from "./api";

export const Create = async (data) => {
  return await POST('/properties', data, true);
}

export const ReadProperties = async () => {
  return await GET('/properties', true);
};

export const ReadProperty = async (id) => {
  return await GET(`/properties/${id}`, true);
};

export const UpdateStatus = async (id, status) => {
  return await PUT(`/properties/${id}`, {
    data: {
      status
    }
  }, true);
}

export const updatePropertyMunicipality = async (id) => {
  return await PUT(`/properties/${id}/update-municipality`, {}, true);
}

export const updateAllMunicipalities = async () => {
  return await PUT('/properties/update-all-municipalities', {}, true);
}

export const GetAvailable = async () => {
  return await GET('/properties/available', true);
}