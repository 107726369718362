import React, { useContext, useState, useEffect } from 'react';
import {
  FilterSection,
  FilterTitle,
  FilterGroup,
  FilterButtons,
  FilterButton,
  ClearButton,
} from './styled';
import { TextField, Autocomplete } from '@mui/material';
import { AuthContext } from '../../../context/contextGlobal/authContext';
import { useFilter } from '../../../context/FilterContext';
import { useProperties } from '../../../context/PropertiesContext';

const FilterContent = ({ onClose, data }) => {
  const {
    setUserProperties,
    setSelectedProduct,
    setMapCenter
  } = useContext(AuthContext);

  const { filters, updateFilters, clearFilters: clearFilterContext } = useFilter();
  const { properties, processedData, filterProperties, clearFilters } = useProperties();

  const getProprietarioOptions = () => {
    if (!processedData || processedData.length === 0) {
      return [];
    }

    const uniqueProprietarios = [...new Set(processedData.map(item => {
      return item.proprietario || item.user?.name || item.user?.username;
    }))].filter(Boolean);

    return uniqueProprietarios.map(name => ({
      label: name,
      code: name
    }));
  };

  const getPropriedadeOptions = () => {
    if (!filters.selectedUser) {
      return [];
    }

    const filteredProperties = processedData.filter(item => 
      item.proprietario === filters.selectedUser.code || 
      item.user?.name === filters.selectedUser.code || 
      item.user?.username === filters.selectedUser.code
    );

    const uniquePropriedades = [...new Set(filteredProperties.map(item => item.name))].filter(Boolean);

    return uniquePropriedades.map(name => ({
      label: name,
      code: name
    }));
  };

  const getBatalhaoOptions = () => {
    let filteredProperties = processedData;

    if (filters.selectedUser) {
      filteredProperties = filteredProperties.filter(item =>
        item.user?.username === filters.selectedUser.code
      );
    }
    if (filters.selectedPropriet) {
      filteredProperties = filteredProperties.filter(item =>
        item.name === filters.selectedPropriet.code
      );
    }

    const uniqueBatalhoes = [...new Set(filteredProperties
      .map(item => item.patrol?.name)
      .filter(Boolean))];

    return uniqueBatalhoes.map(name => ({
      label: name || 'Sem Batalhão',
      code: name
    }));
  };

  const handleApplyFilters = () => {
    filterProperties(filters);
    onClose();
  };

  const handleClearFilters = () => {
    clearFilterContext();
    clearFilters();
    onClose();
  };

  return (
    <>
      <FilterSection>
        <FilterTitle>Proprietário</FilterTitle>
        <Autocomplete
          value={filters.selectedUser}
          onChange={(e, newValue) => {
            updateFilters({
              selectedUser: newValue,
              selectedPropriet: null,
              selectedPatrol: null
            });
          }}
          options={getProprietarioOptions()}
          getOptionLabel={(option) => option?.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecione o proprietário"
              size="small"
              fullWidth
            />
          )}
          isOptionEqualToValue={(option, value) => option?.code === value?.code}
          noOptionsText="Nenhum proprietário encontrado"
          filterOptions={(options, params) => {
            return options.filter(option => 
              option.label.toLowerCase().includes(params.inputValue.toLowerCase())
            );
          }}
          freeSolo={false}
          disablePortal
          blurOnSelect
        />
      </FilterSection>

      <FilterSection>
        <FilterTitle>Propriedade</FilterTitle>
        <Autocomplete
          value={filters.selectedPropriet}
          onChange={(e, newValue) => {
            updateFilters({
              selectedPropriet: newValue,
              selectedPatrol: null
            });
          }}
          options={getPropriedadeOptions()}
          getOptionLabel={(option) => option?.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecione a propriedade"
              size="small"
              fullWidth
            />
          )}
          disabled={!filters.selectedUser}
          isOptionEqualToValue={(option, value) => option?.code === value?.code}
          noOptionsText="Nenhuma propriedade encontrada"
          filterOptions={(options, params) => {
            return options.filter(option => 
              option.label.toLowerCase().includes(params.inputValue.toLowerCase())
            );
          }}
          freeSolo={false}
          disablePortal
          blurOnSelect
        />
      </FilterSection>

      {/* <FilterSection>
        <FilterTitle>Batalhão</FilterTitle>
        <Autocomplete
          value={filters.selectedPatrol}
          onChange={(e, newValue) => {
            updateFilters({ selectedPatrol: newValue });
          }}
          options={getBatalhaoOptions()}
          getOptionLabel={(option) => option?.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecione o batalhão"
              size="small"
            />
          )}
          disabled={!filters.selectedPropriet}
          isOptionEqualToValue={(option, value) => option?.code === value?.code}
        />
      </FilterSection> */}

      <FilterSection>
        <FilterTitle>Status</FilterTitle>
        <TextField
          select
          value={filters.status}
          onChange={(e) => updateFilters({ status: e.target.value })}
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
        >
          <option value="">Selecione o status</option>
          <option value="aprovada">Aprovada</option>
          <option value="reprovada">Reprovada</option>
          <option value="aguardando">Aguardando</option>
          <option value="inativa">Inativa</option>
        </TextField>
      </FilterSection>

      <FilterSection>
        <FilterTitle>Status de Visita</FilterTitle>
        <TextField
          select
          value={filters.statusVisit}
          onChange={(e) => updateFilters({ statusVisit: e.target.value })}
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
        >
          <option value="">Selecione o status</option>
          <option value="visitada">Visitada</option>
          <option value="aguardando">Aguardando</option>
        </TextField>
      </FilterSection>

      <FilterSection>
        <FilterTitle>Área</FilterTitle>
        <TextField
          value={filters.area}
          onChange={(e) => updateFilters({ area: e.target.value })}
          fullWidth
          size="small"
          placeholder="Digite a área"
        />
      </FilterSection>

      <FilterSection>
        <FilterTitle>Endereço</FilterTitle>
        <TextField
          value={filters.municipality}
          onChange={(e) => updateFilters({ municipality: e.target.value })}
          fullWidth
          size="small"
          placeholder="Digite o endereço"
        />
      </FilterSection>

      <FilterSection>
        <FilterTitle>Linha</FilterTitle>
        <TextField
          value={filters.line}
          onChange={(e) => updateFilters({ line: e.target.value })}
          fullWidth
          size="small"
          placeholder="Digite a linha"
        />
      </FilterSection>

      <FilterButtons>
        <ClearButton onClick={handleClearFilters}>
          Limpar Filtros
        </ClearButton>
        <FilterButton
          onClick={handleApplyFilters}
          selected
        >
          Aplicar Filtros
        </FilterButton>
      </FilterButtons>
    </>
  );
};

export default FilterContent;