/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/contextGlobal/authContext";
import { Wrapper, PageTitle, ContentContainer, ChartGrid, ChartPaper, WrapperTablles, TableTitle } from "./styled";
import { Read } from "../../../service/patrols";
import StatisticCard from '../../../components/Cards/StatisticCard';
import ModernTable from '../../../components/Tables/ModernTable';
import StatusButton from '../../../components/Buttons/StatusButton';
import VisitStatusButton from '../../../components/Buttons/VisitStatusButton';
import ModernMap from '../../../components/Maps/ModernMap';
import { useProperties } from '../../../context/PropertiesContext';
import React from 'react';
import { Button } from '@mui/material';
import BarChart from '../../../components/Charts/BarChart';
import DonutChart from '../../../components/Charts/DonutChart';
import { Info } from '@phosphor-icons/react';
import PatrolStatisticCard from '../../../components/Cards/PatrolStatisticCard';
import Carousel from '../../../components/Cards/PatrolStatisticCard/Carousel';
import { getOccurrencesByMunicipality, getOccurrenceDistribution } from '../../../service/occurrences';
import OccurrenceTypeStats from '../../../components/OccurrenceTypeStats';

const HomePage = () => {
    const {
        setPatrols,
        patrols,
        selectedProduct,
        setSelectedProduct,
        setMapCenter,
        setModal
    } = useContext(AuthContext);

    const navigation = useNavigate();
    const { fetchProperties, processedData, filteredData, totals } = useProperties();

    // Estado para controlar loading inicial
    const [isLoading, setIsLoading] = useState(true);

    // Filtra propriedades com status "Aguardando"
    const aguardandoData = React.useMemo(() => {
        return (filteredData?.length > 0 ? filteredData : processedData)?.filter(
            property => property.status?.toLowerCase() === 'aguardando'
        ) || [];
    }, [filteredData, processedData]);

    // Adicione este novo estado
    const [patrolStats, setPatrolStats] = useState({});

    // Adicione este estado para controlar o fullscreen
    const [isFullScreen, setIsFullScreen] = useState(false);

    // Adicione esta função para calcular estatísticas por batalhão
    const calculatePatrolStats = (properties) => {
        const stats = {};

        properties.forEach(property => {
            if (!property.patrol?.name) return;

            const patrolName = property.patrol.name;

            if (!stats[patrolName]) {
                stats[patrolName] = {
                    total: 0,
                    aprovadas: 0,
                    reprovadas: 0,
                    visitadas: 0,
                    aguardando: 0,
                    inativas: 0
                };
            }

            stats[patrolName].total++;

            // Incrementa os contadores baseado no status
            if (property.status?.toLowerCase() === 'ativo' ||
                property.status?.toLowerCase() === 'aprovada' ||
                property.status?.toLowerCase() === 'aprovado') {
                stats[patrolName].aprovadas++;
            }
            if (property.status?.toLowerCase() === 'reprovada' ||
                property.status?.toLowerCase() === 'reprovado') {
                stats[patrolName].reprovadas++;
            }
            if (property.status?.toLowerCase() === 'aguardando') {
                stats[patrolName].aguardando++;
            }
            if (property.status?.toLowerCase() === 'inativo' ||
                property.status?.toLowerCase() === 'inativa') {
                stats[patrolName].inativas++;
            }
            if (property.status_visit?.toLowerCase() === 'visitado' ||
                property.status_visit?.toLowerCase() === 'ativo') {
                stats[patrolName].visitadas++;
            }
        });

        return stats;
    };

    // useEffect para inicialização
    useEffect(() => {
        const init = async () => {
            const token = localStorage.getItem('@token');
            if (!token) {
                navigation('/');
                return;
            }

            try {
                setIsLoading(true);
                await fetchProperties();
                await handlePatrols();
            } catch (error) {
                console.error('Erro na inicialização:', error);
            } finally {
                setIsLoading(false);
            }
        };

        init();
    }, [navigation]); // Removido processedData das dependências

    // useEffect separado para calcular estatísticas
    useEffect(() => {
        if (processedData?.length > 0) {
            const stats = calculatePatrolStats(processedData);
            setPatrolStats(stats);
        }
    }, [processedData]);

    const [chartData, setChartData] = useState({
        bar: [],
        donut: [],
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadOccurrenceData = async () => {
            try {
                setLoading(true);
                const municipalityStats = await getOccurrencesByMunicipality();
                const distribution = await getOccurrenceDistribution();

                // Formata dados para o gráfico de barras
                const barData = [
                    ['Município', 'Ocorrências'],
                    ...municipalityStats.map(item => [item.municipality, item.count])
                ];

                // Formata dados para o gráfico de rosca
                const donutData = [
                    ['Município', 'Porcentagem'],
                    ...distribution.map(item => [item.municipality, Number(item.percentage)])
                ];

                setChartData({
                    bar: barData,
                    donut: donutData
                });
            } catch (error) {
                console.error('Erro ao carregar dados de ocorrências:', error);
                setModal({
                    type: 'warning',
                    title: 'Erro',
                    text: 'Não foi possível carregar os dados de ocorrências.'
                });
            } finally {
                setLoading(false);
            }
        };

        loadOccurrenceData();
    }, []);

    // Colunas da tabela de propriedades
    const columns = [
        {
            field: 'name',
            headerName: 'Nome da Propriedade',
            sortable: true,
            renderCell: (rowData) => rowData.name || 'Não informado'
        },
        {
            field: 'proprietario',
            headerName: 'Nome do Proprietario',
            renderCell: (rowData) => rowData.user?.username || 'Sem proprietário',
            sortable: true
        },
        {
            field: 'property_number',
            headerName: 'Número da Propriedade',
            renderCell: (rowData) => rowData?.property_number || 'Não cadastrado',
            sortable: true
        },
        {
            field: 'area',
            headerName: 'Área',
            sortable: true,
            renderCell: (rowData) => rowData.area || 'Não informado'
        },
        {
            field: 'municipality',
            headerName: 'Endereço',
            sortable: true,
            renderCell: (rowData) => rowData.municipality || 'Não informado'
        },
        {
            field: 'line',
            headerName: 'Linha',
            sortable: true,
            renderCell: (rowData) => rowData.line || 'Não informado'
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData?.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (rowData) => (
                <StatusButton status={rowData.status} rowData={rowData} />
            ),
            sortable: true
        },
        {
            field: 'status_visit',
            headerName: 'Visita',
            renderCell: (rowData) => (
                <VisitStatusButton status={rowData.status_visit} propertyId={rowData.id} />
            ),
            sortable: true
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (rowData) => (
                <Button
                    variant="contained"
                    color="info"
                    startIcon={<Info weight="duotone" />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setModal({
                            type: 'propertyInfo',
                            data: rowData
                        });
                    }}
                    sx={{
                        textTransform: 'none',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                            transform: 'translateY(-1px)',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                        }
                    }}
                >
                    Informações
                </Button>
            ),
            sortable: false
        }
    ];

    // Colunas específicas para a tabela de ocorrências
    const occurrenceColumns = [
        {
            field: 'name',
            headerName: 'Nome da Propriedade',
            sortable: true,
            renderCell: (rowData) => rowData.name || 'Não informado'
        },
        {
            field: 'proprietario',
            headerName: 'Nome do Proprietario',
            renderCell: (rowData) => rowData.user?.username || 'Sem proprietário',
            sortable: true
        },
        {
            field: 'occurrences',
            headerName: 'Qtd. Ocorrências',
            renderCell: (rowData) => rowData.occurrences?.length || 0,
            sortable: true
        },
        {
            field: 'occurrence_type',
            headerName: 'Tipo de Ocorrência',
            renderCell: (rowData) => rowData.occurrences?.map(occurrence => occurrence.occurrence_type).join(', ') || 'Não informado',
            sortable: true
        },
        {
            field: 'municipality',
            headerName: 'Município',
            sortable: true,
            renderCell: (rowData) => rowData.municipality || 'Não informado'
        },
        {
            field: 'patrol',
            headerName: 'Batalhão',
            renderCell: (rowData) => rowData?.patrol?.name || 'Sem Batalhão',
            sortable: true
        },
        // {
        //     field: 'actions',
        //     headerName: 'Ações',
        //     renderCell: (rowData) => (
        //         <Button
        //             variant="contained"
        //             color="warning"
        //             startIcon={<Info weight="duotone" />}
        //             onClick={(e) => {
        //                 e.stopPropagation();
        //                 setModal({
        //                     type: 'propertyInfo',
        //                     data: rowData
        //                 });
        //             }}
        //             sx={{
        //                 textTransform: 'none',
        //                 boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        //                 '&:hover': {
        //                     transform: 'translateY(-1px)',
        //                     boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        //                 }
        //             }}
        //         >
        //             Ver Ocorrências
        //         </Button>
        //     ),
        //     sortable: false
        // }
    ];

    const handlePatrols = async () => {
        try {
            const response = await Read();
            if (response?.data) {
                const formattedPatrols = response.data.map(patrol => ({
                    id: patrol.id,
                    name: patrol.attributes.name,
                    code: patrol.attributes.code,
                    license_plate: patrol.attributes.license_plate
                }));
                setPatrols(formattedPatrols);
            }
        } catch (error) {
            console.error("Erro ao buscar batalhes:", error);
        }
    };

    const handleRowClick = (rowData) => {
        setSelectedProduct(rowData);
        if (rowData.status?.toLowerCase() === 'aguardando') {
            setModal({
                type: 'chooseAction',
                data: rowData
            });
        }
    };

    const propertiesWithOccurrences = processedData?.filter(
        property => property.occurrences?.length > 0
    ) || [];

    return (
        <ContentContainer>
            <PageTitle>Propriedades</PageTitle>

            <Wrapper>
                <StatisticCard title="Total" value={totals.total} />
                <StatisticCard title="Reprovadas" value={totals.reprovadas} />
                <StatisticCard title="Visitadas" value={totals.visitadas} />
                <StatisticCard title="Aguardando" value={totals.aguardando} />
                <StatisticCard title="Aprovadas" value={totals.aprovadas} />
                <StatisticCard title="Inativas" value={totals.inativas} />
            </Wrapper>

            <WrapperTablles>
                <div>
                    <TableTitle>Aguardando</TableTitle>
                    <ModernTable
                        scroll={true}
                        columns={columns}
                        data={aguardandoData}
                        pagination={true}
                        onRowClick={handleRowClick}
                        rowsPerPageOptions={[5, 10, 25]}
                        loading={isLoading}
                    />
                </div>

                <div>
                    <TableTitle>Ocorrências</TableTitle>
                    <ModernTable
                        columns={occurrenceColumns}
                        data={propertiesWithOccurrences}
                        pagination={true}
                        // onRowClick={handleRowClick}
                        rowsPerPageOptions={[5, 10, 25]}
                        loading={isLoading}
                        scroll={true}
                    />
                </div>
            </WrapperTablles>

            <PageTitle>
                Lista de Propriedades
            </PageTitle>

            <ModernTable
                columns={columns}
                data={filteredData?.length > 0 ? filteredData : processedData}
                pagination={true}
                onRowClick={handleRowClick}
                rowsPerPageOptions={[5, 10, 25]}
                loading={isLoading}
            />

            <PageTitle>
                Localização das Propriedades
            </PageTitle>

            <ModernMap
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                properties={filteredData?.length > 0 ? filteredData : processedData}
            />

            <div id="patrol-stats-section">

                <PageTitle>
                    Estatísticas por Batalhão
                </PageTitle>

                <Carousel sx={{ mb: 4 }}>
                    {patrols.map((patrol) => (
                        <PatrolStatisticCard
                            key={patrol.id}
                            patrol={patrol}
                            stats={patrolStats[patrol.name] || {
                                total: 0,
                                aprovadas: 0,
                                reprovadas: 0,
                                visitadas: 0,
                                aguardando: 0,
                                inativas: 0
                            }}
                        />
                    ))}
                </Carousel>
            </div>

            <PageTitle>
                Análise de Ocorrências
            </PageTitle>

            <ChartGrid>
                <ChartPaper>
                    <BarChart
                        data={chartData.bar}
                        title="Ocorrências por Município"
                        loading={loading}
                    />
                </ChartPaper>
                <ChartPaper>
                    <DonutChart
                        data={chartData.donut}
                        title="Distribuição de Ocorrências"
                        loading={loading}
                    />
                </ChartPaper>
            </ChartGrid>

            <PageTitle>
                Totalizadores por Tipo de Ocorrência
            </PageTitle>

            <OccurrenceTypeStats properties={processedData} />

        </ContentContainer>
    );
};

export default HomePage;
