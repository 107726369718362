import { GET } from './api'

export const getLastLocations = async () => {
    try {
        const response = await GET('/officer-locations/last', true)
        return response?.data || []
    } catch (error) {
        console.error('Erro ao buscar localizações:', error)
        return []
    }
}

export const getOfficerLastLocation = async (officerId) => {
    try {
        const response = await GET(`/officer-location/getLastLocation/${officerId}`, true)
        return response?.data || null
    } catch (error) {
        console.error('Erro ao buscar localização do policial:', error)
        return null
    }
}

export const getOfficerLocationHistory = async (officerId, startDate, endDate) => {
    try {
        const params = new URLSearchParams({
            officer: officerId,
            ...(startDate && { startDate: startDate.toISOString() }),
            ...(endDate && { endDate: endDate.toISOString() })
        })
        
        const response = await GET(`/officer-locations?${params}`, true)
        return response?.data || []
    } catch (error) {
        console.error('Erro ao buscar histórico de localizações:', error)
        return []
    }
} 