import { useContext } from 'react'

import { AuthContext } from '../../../context/contextGlobal/authContext'
import ModalSample from '../Sample'
import ChooseActionModal from '../ChooseAction'
import ApprovePropertyModal from '../ApproveProperty'
import RejectPropertyModal from '../RejectProperty'
import InactivatePropertyModal from '../InactivateProperty'
import PropertyInfoModal from '../PropertyInfo'
import AssignOrdersModal from '../AssignOrders'

export default function ModalCore() {
  const { modal } = useContext(AuthContext)

  return (
    <>
      {modal?.type === 'sample' && <ModalSample />}
      {modal?.type === 'chooseAction' && <ChooseActionModal />}
      {modal?.type === 'approveProperty' && <ApprovePropertyModal />}
      {modal?.type === 'rejectProperty' && <RejectPropertyModal />}
      {modal?.type === 'inactivateProperty' && <InactivatePropertyModal />}
      {modal?.type === 'propertyInfo' && <PropertyInfoModal />}
      {modal?.type === 'assignOrders' && <AssignOrdersModal />}
    </>
  )
}