import { GET } from './api';

export const getOccurrencesByRegion = async (lat, long, radius = 5) => {
  try {
    const response = await GET('/occurrences/region', true, {
      params: { lat, long, radius }
    });
    return response;
  } catch (error) {
    console.error('Erro ao buscar ocorrências por região:', error);
    throw error;
  }
};

export const getOccurrenceStatistics = async () => {
  try {
    const response = await GET('/occurrences/statistics', true);
    return response;
  } catch (error) {
    console.error('Erro ao buscar estatísticas de ocorrências:', error);
    throw error;
  }
};

export const getOccurrencesByMunicipality = async () => {
  try {
    const response = await GET('/occurrences/municipality-stats', true);
    return response;
  } catch (error) {
    console.error('Erro ao buscar estatísticas por município:', error);
    throw error;
  }
};

export const getOccurrenceDistribution = async () => {
  try {
    const response = await GET('/occurrences/distribution', true);
    return response;
  } catch (error) {
    console.error('Erro ao buscar distribuição de ocorrências:', error);
    throw error;
  }
};

export const GetAll = async () => {
  return await GET('/occurrences', true);
};

export const GetAvailable = async () => {
  return await GET('/occurrences/available', true);
}; 