import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            light: '#D5D96C',    // Verde limão
            main: '#A3A65B',     // Verde oliva
            dark: '#51594C',     // Verde escuro
            pale: '#D9D5A0',     // Bege claro
            contrastText: '#FFFFFF',
        },
        text: {
            primary: '#FFFFFF',     // Branco para melhor contraste
            secondary: '#D5D96C',   // Verde limão para textos secundários
            disabled: '#51594C',    // Verde escuro para textos desabilitados
            light: '#FFFFFF',       // Branco para textos claros
        },
        background: {
            default: '#1A1E17',    // Verde muito escuro para fundo principal
            paper: '#2C312A',      // Verde escuro para cards
            input: '#1A1E17',      // Verde muito escuro para inputs
        },
        colors: {
            black: '#151815',      // Verde quase preto
            lightblack: '#51594C', // Verde escuro
            white: '#FFFFFF',      // Branco puro
            border: '#51594C',     // Verde escuro para bordas
            shadow: 'rgba(0, 0, 0, 0.3)' // Sombra mais escura
        },
        input: {
            label: '#D5D96C',      // Verde limão para labels
            text: '#FFFFFF',       // Branco para texto
            background: '#1A1E17',  // Verde muito escuro
            border: {
                default: '#51594C', // Verde escuro
                focus: '#A3A65B',   // Verde oliva
                error: '#ff5252',   // Vermelho para erros
            }
        },
        map: {
            marker: {
                primary: '#D5D96C', // Verde limão para maior destaque
                glow: 'rgba(213, 217, 108, 0.3)',
                shadow: 'rgba(0, 0, 0, 0.4)',
            }
        },
        button: {
            primary: '#A3A65B',    // Verde oliva
            hover: '#D5D96C',      // Verde limão
            active: '#51594C',     // Verde escuro
            disabled: '#2C312A',   // Verde escuro mais claro
        },
        common: {
            black: '#151815',      // Verde quase preto
            white: '#FFFFFF',      // Branco puro
        },
        shadow: 'rgba(0, 0, 0, 0.25)',
        divider: '#51594C',        // Verde escuro para divisores
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2C312A',
                    borderColor: '#51594C',
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2C312A',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: '#2C312A',
                        color: '#51594C',
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF', // Texto branco para tabelas
                    borderColor: '#51594C',
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1A1E17',
                    '& .MuiTableCell-root': {
                        color: '#D5D96C',
                        fontWeight: 600,
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#2C312A', // background.paper
                    color: '#FFFFFF',
                    border: '1px solid #51594C',
                },
                listbox: {
                    backgroundColor: '#2C312A',
                    '& .MuiAutocomplete-option': {
                        color: '#FFFFFF',
                    },
                    '& .MuiAutocomplete-option[aria-selected="true"]': {
                        backgroundColor: '#A3A65B',
                    },
                    '& .MuiAutocomplete-option:hover': {
                        backgroundColor: '#51594C',
                    },
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1A1E17', // background.input
                    '& fieldset': {
                        borderColor: '#51594C',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A3A65B',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#A3A65B',
                    },
                },
                input: {
                    color: '#FFFFFF',
                    '&::placeholder': {
                        color: '#51594C',
                        opacity: 1,
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-root': {
                        color: '#D5D96C',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#A3A65B',
                    },
                    '& .MuiSelect-icon': {
                        color: '#FFFFFF',
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: '#FFFFFF',
                },
                icon: {
                    color: '#FFFFFF',
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2C312A',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#51594C',
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#A3A65B',
                        '&:hover': {
                            backgroundColor: '#51594C',
                        }
                    }
                }
            }
        },
    }
});  