import styled from "styled-components";

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 24px;
    margin-bottom: 32px;
    width: 100%;
    padding: 8px 4px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: 20px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 16px;
        margin-bottom: 24px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            height: 8px;
        }
        
        &::-webkit-scrollbar-track {
            background: ${props => props.theme.palette.background.default};
            border-radius: 4px;
        }
        
        &::-webkit-scrollbar-thumb {
            background: ${props => props.theme.palette.primary.main}40;
            border-radius: 4px;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: ${props => props.theme.palette.primary.main}60;
        }
    }
`;

export const PageTitle = styled.h1`
    font-size: 28px;
    font-weight: 600;
    color: ${props => props.theme.palette.primary.main};
    text-align: center;
    margin: 0;
    padding: 20px 0;
    position: relative;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    margin: 20px 0px;

    &:after {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 160px;
        height: 2px;
        background:${props => props.theme.palette.primary.main};
        opacity: 0.3;
    }
`;

export const ContentContainer = styled.div`
    padding: 0 20px;
    /* max-width: 1600px; */
    margin: 0 auto;
    width: 100%;
`;

export const ChartGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 24px;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`;

export const ChartPaper = styled.div`
    background: ${props => props.theme.palette.background.paper};
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.1);
    
    h3 {
        color: ${props => props.theme.palette.primary.light} !important;
        font-size: 1.2rem;
        margin-bottom: 16px;
        font-weight: 600;
    }
`;

export const SectionTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 32px 0 24px;

    h2 {
        color: ${props => props.theme.palette.primary.light};
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
    }
`;

export const TableTitle = styled.h3`
    color: ${props => props.theme.palette.primary.main};
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 16px 0;
    padding: 0;
    text-transform: uppercase;
`;

export const WrapperTablles = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;

    > div {
        flex: 1;
        margin: 0;
        min-width: 0;
        
        .MuiTableContainer-root {
            margin-bottom: 0;
        }
    }
`;